a,
abbr,
address,
applet,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

b,
mark,
strong {
  font-weight: 700;
  font-style: inherit;
}

address,
cite,
dfn,
em,
i,
q,
var {
  font-style: italic;
  font-weight: inherit;
}

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted;
}

ins {
  border-bottom: 1px solid;
}

a,
ins,
u {
  text-decoration: none;
}

del,
s {
  text-decoration: line-through;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
}

small {
  font-size: 0.75em;
}

img {
  border: 0;
  font-style: italic;
}

input,
optgroup,
option,
select,
textarea {
  font: inherit;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio[controls],
canvas,
video {
  display: inline-block;
}

html {
  font-size: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  text-rendering: optimizeLegibility;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  display: block;
}

embed,
img,
object {
  max-width: 100%;
  height: auto;
}

input {
  -webkit-appearance: none;
}

footer,
header,
section {
  position: relative;
  clear: both;
  width: 100%;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateX(0) rotateY(0);
    transform: translate(-50%, -50%) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateX(0) rotateY(0);
    transform: translate(-50%, -50%) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
  }
}

body,
html {
  height: 100%;
}

body {
  background: #fff;
  font-family: Muli, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  color: #6c7a89;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Merriweather, sans-serif;
  color: #2a2f36;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

.h1,
h1 {
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

@media only screen and (min-width: 768px) {
  .h1,
  h1 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1220px) {
  .h1,
  h1 {
    font-size: 45px;
  }
}

.h1 a,
h1 a {
  font-weight: 400;
}

.h2,
h2 {
  margin-top: 40px;
  font-size: 25px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

@media only screen and (min-width: 768px) {
  .h2,
  h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1220px) {
  .h2,
  h2 {
    font-size: 45px;
  }
}

.h2 a,
h2 a {
  font-weight: 400;
}

.h3,
h3 {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.02em;
}

@media only screen and (min-width: 768px) {
  .h3,
  h3 {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1220px) {
  .h3,
  h3 {
    font-size: 30px;
  }
}

.h4,
h4 {
  margin-top: 25px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media only screen and (min-width: 768px) {
  .h4,
  h4 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1220px) {
  .h4,
  h4 {
    font-size: 22px;
  }
}

.h5,
h5 {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media only screen and (min-width: 768px) {
  .h5,
  h5 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1220px) {
  .h5,
  h5 {
    font-size: 20px;
  }
}

.h6,
h6 {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media only screen and (min-width: 768px) {
  .h6,
  h6 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1220px) {
  .h6,
  h6 {
    font-size: 20px;
  }
}

ol,
p,
ul {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0;
}

@media only screen and (min-width: 768px) {
  ol,
  p,
  ul {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1220px) {
  ol,
  p,
  ul {
    font-size: 20px;
  }
}

ol strong,
p strong,
ul strong {
  font-weight: 600;
}

ol:first-child,
p:first-child,
ul:first-child {
  margin-top: 0;
}

ol,
ul {
  list-style-position: inside;
}

blockquote {
  margin-top: 15px;
  font-family: Merriweather, sans-serif;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6;
}

@media only screen and (min-width: 768px) {
  blockquote {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1220px) {
  blockquote {
    font-size: 45px;
  }
}

blockquote p {
  font-size: 25px;
  font-weight: 400;
  letter-spacing: -0.02em;
  line-height: 1.6;
}

@media only screen and (min-width: 768px) {
  blockquote p {
    font-size: 35px;
  }
}

@media only screen and (min-width: 1220px) {
  blockquote p {
    font-size: 45px;
  }
}

a {
  text-decoration: none;
  color: #2a2f36;
  font-weight: 600;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

a:hover {
  color: #a2ded0;
  cursor: pointer;
}

.page-loader {
  display: none;
}

.page {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.page,
.page__content {
  float: left;
  clear: both;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .page {
    padding-left: 30%;
  }
}

@media only screen and (min-width: 1600px) {
  .page {
    padding-left: 25%;
  }
}

section {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  section {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1024px) {
  section {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1220px) {
  section {
    margin-top: 80px;
  }
}

.wrap {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
}

@media only screen and (min-width: 768px) {
  .wrap {
    padding: 0 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .wrap {
    padding: 0;
  }
}

.header {
  padding: 40px 20px;
  background: rgb(35, 39, 65);
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 80px 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .header {
    position: fixed;
    z-index: 10;
    overflow: auto;
    top: 0;
    left: 0;
    bottom: 0;
    width: 25%;
    padding: 0;
  }
}

/* @media only screen and (min-width:1600px) {
	.header {
		width: 25%
	}
} */

.header-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

@media only screen and (min-width: 1024px) {
  .header-image {
    position: fixed;
    right: auto;
    width: 30%;
  }
}

@media only screen and (min-width: 1600px) {
  .header-image {
    width: 25%;
  }
}

.header-image--on {
  opacity: 1;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(22, 27, 33, 0.7);
}

@media only screen and (min-width: 1024px) {
  .header-overlay {
    background: -moz-linear-gradient(
      top,
      rgba(22, 27, 33, 0) 0,
      rgba(22, 27, 33, 0.01) 1%,
      rgba(22, 27, 33, 0.7) 70%,
      rgba(22, 27, 33, 0.7) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(22, 27, 33, 0) 0,
      rgba(22, 27, 33, 0.01) 1%,
      rgba(22, 27, 33, 0.7) 70%,
      rgba(22, 27, 33, 0.7) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(22, 27, 33, 0) 0,
      rgba(22, 27, 33, 0.01) 1%,
      rgba(22, 27, 33, 0.7) 70%,
      rgba(22, 27, 33, 0.7) 100%
    );
  }
}

.header__content {
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .header__content {
    position: absolute;
    bottom: 0;
    padding: 40px 40px 34px;
  }
}

@media only screen and (min-width: 1220px) {
  .header__content {
    padding: 60px 60px 52px;
  }
}

.header__title {
  font-family: Merriweather, sans-serif;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1;
  color: #fff;
}

.header__title:hover {
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .header__title {
    font-size: 30px;
  }
}

@media only screen and (min-width: 1220px) {
  .header__title {
    font-size: 35px;
  }
}

.header__tagline {
  margin-top: 10px;
  font-family: Muli, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (min-width: 768px) {
  .header__tagline {
    font-size: 17px;
  }
}

@media only screen and (min-width: 1220px) {
  .header__tagline {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .menu {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1220px) {
  .menu {
    margin-top: 30px;
  }
}

.menu__toggle {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
}

@media only screen and (min-width: 1024px) {
  .menu__toggle {
    display: none;
  }
}

.menu__toggle__icon {
  float: right;
  position: relative;
  width: 24px;
  height: 20px;
  margin-left: 8px;
}

.menu__toggle__icon span,
.menu__toggle__icon span:after,
.menu__toggle__icon span:before {
  content: '';
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 3px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
  background: rgba(255, 255, 255, 0.7);
}

.menu__toggle__icon span:before {
  top: -8px;
}

.menu__toggle__icon span:after {
  top: 8px;
}

.menu--open .menu__toggle__icon span:after,
.menu--open .menu__toggle__icon span:before {
  background: #fff !important;
}

.menu--open .menu__toggle__icon span {
  background-color: transparent !important;
}

.menu--open .menu__toggle__icon span:after,
.menu--open .menu__toggle__icon span:before {
  top: 0;
}

.menu--open .menu__toggle__icon span:before {
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu--open .menu__toggle__icon span:after {
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu__wrap {
  position: fixed;
  z-index: 90;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background: rgba(22, 27, 33, .95); */
  overflow: auto;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 1024px) {
  .menu__wrap {
    position: relative;
    background: 0 0;
    visibility: visible;
    overflow: visible;
    opacity: 1;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.menu--open .menu__wrap {
  visibility: visible;
  opacity: 1;
}

.menu__list {
  list-style: none;
  margin: 0 auto;
  padding: 12px 20px;
}

@media only screen and (min-width: 768px) {
  .menu__list {
    padding: 30px 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .menu__list {
    margin: 0;
    padding: 0;
  }
}

.menu__list__item__link {
  font-size: 30px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px 0;
}

.menu__list__item__link:hover {
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .menu__list__item__link {
    padding: 0;
    font-size: 17px;
  }
}

@media only screen and (min-width: 1220px) {
  .menu__list__item__link {
    font-size: 18px;
  }
}

.menu__list__item__link.active-link {
  color: #fff;
}

.menu__button {
  margin-top: 15px;
  padding: 15px 20px 16px;
  font-size: 13px;
}

@media only screen and (min-width: 1024px) {
  .menu__button {
    margin-top: 0;
    padding: 11px 20px 12px;
    font-size: 15px;
  }
}

.header .socials {
  padding: 12px 20px;
}

@media only screen and (min-width: 768px) {
  .header .socials {
    padding: 10px 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .header .socials {
    margin: 27px 0 0;
    padding: 0;
  }
}

@media only screen and (min-width: 1220px) {
  .header .socials {
    margin-top: 35px;
  }
}

.header .socials .socials__item__link {
  background: rgba(255, 255, 255, 0.7);
  color: #161b21;
}

.header .socials .socials__item__link:hover {
  background: #fff;
  color: #161b21;
}

.footer {
  -webkit-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  -moz-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  -ms-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  -o-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 40px;
  }
}

@media only screen and (min-width: 1220px) {
  .footer {
    padding: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    padding-left: 30%;
  }
}

@media only screen and (min-width: 1600px) {
  .footer {
    padding-left: 25%;
  }
}

.footer__copyright {
  display: block;
  font-size: 13px;
  color: #abb7b7;
}

@media only screen and (min-width: 1024px) {
  .footer__copyright {
    padding-left: 40px;
  }
}

@media only screen and (min-width: 1220px) {
  .footer__copyright {
    padding-left: 60px;
  }
}

.footer__copyright a {
  margin-left: 10px;
  color: #abb7b7;
  font-weight: 400;
}

.footer__copyright a:hover {
  color: #6c7a89;
}

.intro {
  margin-top: 0;
  padding: 20px 0 0;
}

@media only screen and (min-width: 768px) {
  .intro {
    padding: 35px 0 0;
  }
}

@media only screen and (min-width: 1024px) {
  .intro {
    padding: 35px 40px 0;
  }
}

@media only screen and (min-width: 1220px) {
  .intro {
    padding: 53px 60px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .intro .wrap {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1220px) {
  .intro .wrap {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1600px) {
  .intro .wrap {
    max-width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .intro h1 {
    white-space: pre-line;
  }
}

.intro p {
  margin-top: 5px;
  color: #abb7b7;
}

.single {
  margin-top: 0;
  padding: 20px 0;
  min-height: 100vh;
}

@media only screen and (min-width: 768px) {
  .single {
    padding: 40px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .single {
    padding: 30px 40px 40px;
  }
}

@media only screen and (min-width: 1220px) {
  .single {
    padding: 40px 60px 60px;
  }
}

.single h1,
.single h2,
.single h3,
.single h4,
.single h5,
.single h6,
.single ol,
.single p,
.single ul {
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .single h1,
  .single h2,
  .single h3,
  .single h4,
  .single h5,
  .single h6,
  .single ol,
  .single p,
  .single ul {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1220px) {
  .single h1,
  .single h2,
  .single h3,
  .single h4,
  .single h5,
  .single h6,
  .single ol,
  .single p,
  .single ul {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1600px) {
  .single h1,
  .single h2,
  .single h3,
  .single h4,
  .single h5,
  .single h6,
  .single ol,
  .single p,
  .single ul {
    max-width: 70%;
  }
}

.single hr {
  margin: 20px auto;
}

@media only screen and (min-width: 768px) {
  .single hr {
    margin-top: 40px;
    margin-bottom: 40px;
    width: auto;
  }
}

@media only screen and (min-width: 1024px) {
  .single hr {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1220px) {
  .single hr {
    max-width: 75%;
  }
}

@media only screen and (min-width: 1600px) {
  .single hr {
    max-width: 70%;
  }
}

.single blockquote,
.single figure {
  margin: 20px auto;
}

@media only screen and (min-width: 768px) {
  .single blockquote,
  .single figure {
    margin: 30px auto;
  }
}

@media only screen and (min-width: 1220px) {
  .single blockquote,
  .single figure {
    margin: 40px auto;
  }
}

.single blockquote p {
  max-width: 100%;
  padding: 0;
}

.single p a {
  font-weight: 600;
  border-bottom: 2px solid #a2ded0;
}

.single p .button {
  margin-top: 10px;
}

.single .highlighter-rouge,
.single table {
  margin: 20px auto;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .single .highlighter-rouge,
  .single table {
    margin: 30px auto;
  }
}

@media only screen and (min-width: 1024px) {
  .single .highlighter-rouge,
  .single table {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1220px) {
  .single .highlighter-rouge,
  .single table {
    margin: 40px auto;
    max-width: 75%;
  }
}

@media only screen and (min-width: 1600px) {
  .single .highlighter-rouge,
  .single table {
    max-width: 70%;
  }
}

.single .highlight {
  display: block;
  padding: 0;
  line-height: 1.5;
  font-size: 13.6px;
  line-height: 1.6;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .single .highlight {
    font-size: 15.3px;
  }
}

@media only screen and (min-width: 1220px) {
  .single .highlight {
    font-size: 17px;
  }
}

.single .highlight .highlight {
  padding: 20px;
}

@media only screen and (min-width: 768px) {
  .single .highlight .highlight {
    padding: 30px;
  }
}

@media only screen and (min-width: 1220px) {
  .single .highlight .highlight {
    padding: 40px;
  }
}

.single table {
  border: 2px solid #2a2f36;
}

.single table thead {
  background: #2a2f36;
  color: #fff;
  text-align: left;
  font-weight: 600;
}

.single table thead th {
  font-weight: 600;
}

.single table td,
.single table th {
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) {
  .single table td,
  .single table th {
    padding: 15px;
  }
}

@media only screen and (min-width: 1220px) {
  .single table td,
  .single table th {
    padding: 20px;
  }
}

.single table tr:nth-child(2n) {
  background: #f5f7fa;
}

.image-wrap,
.video-wrap {
  position: relative;
  margin: 20px auto;
}

@media only screen and (min-width: 768px) {
  .image-wrap,
  .video-wrap {
    margin: 30px auto;
  }
}

@media only screen and (min-width: 1220px) {
  .image-wrap,
  .video-wrap {
    margin: 40px auto;
  }
}

.image-wrap:first-child,
.video-wrap:first-child {
  margin-top: 0;
}

.image-wrap img {
  margin: 0 auto;
}

.video {
  position: relative;
  width: 100%;
  height: 0;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.skills {
  display: flex;
  flex-flow: wrap;
}

.chip {
  color: rgba(0, 0, 0, 0.87);
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: #e0e0e0;
  margin: 10px;
}

@media only screen and (min-width: 1024px) {
  .chip {
    margin: 20px;
  }
}

.chip__label {
  cursor: inherit;
  display: flex;
  align-items: center;
  user-select: none;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
}

.chip__label:hover {
  background: rgb(35, 39, 65);
  border-radius: 16px;
  color: #fff;
}

.socials {
  list-style: none;
  margin: 0;
  padding: 0;
}

.socials__item {
  display: inline-block;
  margin-left: 10px;
}

.socials__item:first-child {
  margin-left: 0;
}

.socials__item__link {
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #abb7b7;
  font-size: 17px;
  line-height: 1;
  color: #fff;
}

@media only screen and (min-width: 768px) {
  .socials__item__link {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.socials__item__link svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -52%);
  -o-transform: translate(-50%, -52%);
  -ms-transform: translate(-50%, -52%);
  -webkit-transform: translate(-50%, -52%);
  transform: translate(-50%, -52%);
}

.socials__item__link:hover {
  background: #a2ded0;
  color: #fff;
}

.portfolio {
  margin-top: 0;
  padding: 20px 0 0;
}

@media only screen and (min-width: 768px) {
  .portfolio {
    padding: 35px 0 0;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio {
    padding: 35px 40px 0;
  }
}

@media only screen and (min-width: 1220px) {
  .portfolio {
    padding: 53px 60px 0;
  }
}

.portfolio-item {
  position: relative;
  margin-top: 20px;
  display: flex;
}

@media only screen and (min-width: 768px) {
  .portfolio-item {
    margin-top: 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .portfolio-item {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1220px) {
  .portfolio-item {
    margin-top: 60px;
  }
}

.portfolio-item:first-child {
  margin-top: 0;
}

.portfolio-item__link {
  position: relative;
  display: block;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .portfolio-item__link:hover .portfolio-item__content {
    opacity: 1;
    visibility: visible;
  }
}

.portfolio-item__image {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.portfolio-item__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 27, 33, 0.7);
  /* opacity: 0;
  visibility: hidden; */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.portfolio-item__info {
  text-align: center;
}

.portfolio-item__title {
  color: #fff;
}

.portfolio-item__subtitle {
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.7);
}

.portfolio-item__codelink {
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: column;
  text-decoration: underline;
}

.blog {
  margin-top: 0;
  padding: 20px 0 0;
}

@media only screen and (min-width: 768px) {
  .blog {
    padding: 35px 0 0;
  }
}

@media only screen and (min-width: 1024px) {
  .blog {
    padding: 35px 40px 0;
  }
}

@media only screen and (min-width: 1220px) {
  .blog {
    padding: 53px 60px 0;
  }
}

.blog-post {
  margin-top: 40px;
}

@media only screen and (min-width: 768px) {
  .blog-post {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .blog-post {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 1220px) {
  .blog-post {
    margin-top: 100px;
  }
}

.blog-post:first-child {
  margin-top: 0;
}

.blog-post__image {
  margin-top: 20px;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) {
  .blog-post__image {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .blog-post__image {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1220px) {
  .blog-post__image {
    margin-top: 40px;
  }
}

.blog-post__content {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .blog-post__content {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .blog-post__content {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 1220px) {
  .blog-post__content {
    margin-top: 40px;
  }
}

.blog-post__content p .button {
  margin-top: 5px;
}

@media only screen and (min-width: 1024px) {
  .blog-post__content p .button {
    margin-top: 10px;
  }
}

.blog-post__subtitle {
  margin-top: 5px;
  color: #abb7b7;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}

.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.svg-inline--fa.fa-w-9 {
  width: 0.5625em;
}

.svg-inline--fa.fa-w-16 {
  width: 1em;
}

.svg-inline--fa.fa-w-14 {
  width: 0.875em;
}

.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}
