@media only screen and (min-width: 1024px) {
  .particles {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 25%;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    position: sticky;
    top: 0px;
    width: 100%;
    padding: 40px 20px 0px 20px;
    z-index: 1;
  }
  .particles {
    display: none;
  }
  .menu__list {
    padding-left: 0px;
  }
  ul.menu__list li {
    display: inline;
    padding: 0px 10px 0px 0px;
  }
  .menu__wrap {
    position: inherit;
    visibility: visible;
    opacity: 1;
  }
  .menu__list__item__link {
    font-size: 15px;
  }
}

.vertical-timeline-element-content.bounce-in:hover {
  background: aliceblue;
}
